<template>
  <v-app>
    <div class="grey lighten-3 flex-grow-1 d-flex flex-column align-center">
      <v-card class="my-auto pa-4" style="width: 450px">
        <ValidationObserver v-slot="{handleSubmit}">
          <v-card-text>
            <template v-if="!verifying">
              <h2 class="mb-8">{{ $t('account.loginViaOTP') }}</h2>

              <div class="mb-8">
                <v-tabs grow color="primary" v-model="tabIndex">
                  <v-tab> {{ $t('account.admin') }} </v-tab>
                  <v-tab> {{ $t('organizations.organization') }} </v-tab>
                </v-tabs>
              </div>

              <div class="mb-8">
                <v-tabs color="primary" v-model="usernameType" @change="changeType">
                  <v-tab> {{ $t('shared.mobile') }} </v-tab>
                  <v-tab> {{ $t('shared.email') }} </v-tab>
                </v-tabs>
              </div>

              <v-tabs-items v-model="usernameType">
                <v-tab-item>
                  <mobile-input
                    v-model="model.mobile"
                    :code.sync="model.countryCode"
                    dir="ltr"
                    key="mobile"
                    :label="$t('shared.mobile')"
                    class="mb-2"
                    :rules="mobileRules"
                    @keydown.enter="handleSubmit(requestOtp)"
                  />
                </v-tab-item>

                <v-tab-item>
                  <c-input
                    v-model="model.email"
                    dir="ltr"
                    key="email"
                    :label="$t('shared.email')"
                    class="mb-2"
                    placeholder="example@gmail.com"
                    :rules="emailRules"
                    @keydown.enter="handleSubmit(requestOtp)"
                  />
                </v-tab-item>
              </v-tabs-items>

              <v-btn color="success" block elevation="1" @click="handleSubmit(requestOtp)" :loading="progressing">
                {{ $t('account.sendOtp') }}
              </v-btn>
            </template>

            <template v-else>
              <h2 class="mb-4">{{ $t('account.verifyMobileNumber') }}</h2>

              <div class="mb-8 grey--text">
                {{ $t('account.enterTheCodeIsSendedToYourMobileOrEmail') }}
              </div>

              <c-input
                v-model="loginModel.code"
                :name="$t('shared.code')"
                dir="ltr"
                maxlength="6"
                key="code"
                :label="$t('shared.code')"
                class="mb-4"
                input-class="text-center"
                placeholder="- - - - - -"
                rules="required|numeric|digits:6"
                @keydown.enter="handleSubmit(login)"
              />

              <div class="d-flex mb-8 align-center">
                <div v-if="counter" class="grey--text">
                  <span>{{ timer }} {{ $t('shared.toResendAgain') }} </span>
                </div>

                <v-btn v-else color="secondary" small @click="requestOtp" :loading="progressing">
                  {{ $t('account.resendCode') }}
                </v-btn>

                <v-spacer></v-spacer>
                <a @click="verifying = false"> {{ $t('shared.editMobile') }} </a>
              </div>

              <v-btn block color="success" @click="handleSubmit(login)" :loading="progressing">
                {{ $t('account.login') }}
              </v-btn>
            </template>

            <v-btn block text color="secondary" class="mt-8" to="/login">
              {{ $t('account.loginViaPassword') }}
            </v-btn>
          </v-card-text>
        </ValidationObserver>
      </v-card>

      <OrgSelector v-model="openDialog" @select="setCurrentOrg" />
    </div>
  </v-app>
</template>

<script>
import {mapMutations} from 'vuex'
import OrgSelector from './components/OrgSelector'
export default {
  name: 'Login',
  layout: 'empty',
  meta: {
    label: 'home'
  },
  components: {
    OrgSelector
  },
  data() {
    return {
      progressing: false,
      verifying: false,
      openDialog: false,
      tabIndex: 0,
      usernameType: 0,
      counter: 120,
      timer: '2:00',
      loginModel: {
        countryCode: 'AU',
        code: null,
        uniqueId: null,
        key: null
      },
      model: {
        email: null,
        countryCode: 'AU',
        mobile: null,
        sendViaEmail: false,
        sendViaSms: true
      }
    }
  },
  computed: {
    mobileRules() {
      const rules = {}
      if (!this.usernameType) {
        rules.required = true
      }
      return rules
    },
    emailRules() {
      const rules = {email: true}
      if (this.usernameType) {
        rules.required = true
      }
      return rules
    }
  },
  methods: {
    ...mapMutations({
      setAccount: 'SET_ACCOUNT',
      setOrgId: 'SET_ORG_ID'
    }),
    changeType() {
      if (this.usernameType) {
        this.model.mobile = null
      } else {
        this.model.email = null
      }
    },
    runTimer() {
      this.counter = 120
      this.startTimer()
    },
    startTimer() {
      const interval = setInterval(() => {
        if (this.counter > 0) {
          this.counter -= 1
          const m = Math.floor(this.counter / 60)
          const s = this.counter % 60
          this.timer = `${m}:${s}`
        } else {
          clearInterval(interval)
        }
      }, 1000)
    },
    requestOtp() {
      this.progressing = true
      if (this.usernameType) {
        this.model.sendViaEmail = true
        this.model.sendViaSms = false
      } else {
        this.model.sendViaSms = true
        this.model.sendViaEmail = false
      }
      return this.$api.accountPub
        .sendOtp(this.model)
        .then((res) => {
          this.loginModel.key = this.model.mobile || this.model.email
          this.loginModel.countryCode = this.model.countryCode
          this.$showSuccess(this.$t('shared.otpSendedToYourMobile'))
          this.verifying = true
          this.runTimer()
        })
        .catch((err) => {
          this.$showError(err)
        })
        .finally(() => {
          this.progressing = false
        })
    },
    async login() {
      const scope = this.tabIndex ? 'ORG' : 'ADMIN'
      if (this.model.sendViaSms) {
        this.model.email = null
      } else {
        this.model.mobile = null
      }
      this.loginModel.key = this.model.mobile || this.model.email

      this.progressing = true
      try {
        const res = await this.$api.accountPub.loginByOtp(this.loginModel, scope)
        this.setAccount(res)
        if (!this.hasAnyPermission(res)) {
          this.$showError(this.$t('errors.ACCESS_DENIED'))
          return
        }
        if (this.tabIndex) {
          if (res.organizations && res.organizations.length === 1) {
            this.setCurrentOrg(res.organizations[0])
          } else {
            this.openDialog = true
          }
        } else {
          this.gotoTargetPage()
        }
      } catch (err) {
        this.$showError(err)
      } finally {
        this.progressing = false
      }
    },
    hasAnyPermission(userInfo) {
      let hasPermission = true

      if (this.tabIndex) {
        if (this.$isEmpty(userInfo.organizations)) {
          hasPermission = false
        } else {
          const hasNoOrgPermission = userInfo.organizations.every((org) => this.$isEmpty(org.permissions))
          if (hasNoOrgPermission) {
            hasPermission = false
          }
        }
      } else if (this.$isEmpty(userInfo.permissions)) {
        hasPermission = false
      }

      return hasPermission
    },
    gotoTargetPage() {
      const homePageUrl = this.$config.homeUrl
      const returnUrl = this.$route.query.go
      const path = returnUrl || homePageUrl
      this.$router.push(path)
    },
    setCurrentOrg(org) {
      this.setOrgId(org.id)
      this.gotoTargetPage()
    }
  }
}
</script>
